import { default as billingds1GSpP5J9Meta } from "/home/runner/work/rootle/rootle/client/src/pages/account/billing.vue?macro=true";
import { default as profile6gcNG2V2dzMeta } from "/home/runner/work/rootle/rootle/client/src/pages/account/profile.vue?macro=true";
import { default as securityRZJElRZMZuMeta } from "/home/runner/work/rootle/rootle/client/src/pages/account/security.vue?macro=true";
import { default as settingsBMycaL8Y1xMeta } from "/home/runner/work/rootle/rootle/client/src/pages/account/settings.vue?macro=true";
import { default as workspacesA29grnY00YMeta } from "/home/runner/work/rootle/rootle/client/src/pages/account/workspaces.vue?macro=true";
import { default as accountCAdEA4fxdXMeta } from "/home/runner/work/rootle/rootle/client/src/pages/account.vue?macro=true";
import { default as cronjobsUnG0NEBOFvMeta } from "/home/runner/work/rootle/rootle/client/src/pages/admin/cronjobs.vue?macro=true";
import { default as explorerkcFoqTi3RzMeta } from "/home/runner/work/rootle/rootle/client/src/pages/admin/explorer.vue?macro=true";
import { default as usageLJDoIeT6e1Meta } from "/home/runner/work/rootle/rootle/client/src/pages/admin/usage.vue?macro=true";
import { default as users2ZYDSbBkthMeta } from "/home/runner/work/rootle/rootle/client/src/pages/admin/users.vue?macro=true";
import { default as adminFcI9I07q5PMeta } from "/home/runner/work/rootle/rootle/client/src/pages/admin.vue?macro=true";
import { default as dropboxOYLR73XTYaMeta } from "/home/runner/work/rootle/rootle/client/src/pages/auth/apps/dropbox.vue?macro=true";
import { default as failurep35BSj4buDMeta } from "/home/runner/work/rootle/rootle/client/src/pages/auth/failure.vue?macro=true";
import { default as forgot_45passwordvYvNoCkFHYMeta } from "/home/runner/work/rootle/rootle/client/src/pages/auth/forgot-password.vue?macro=true";
import { default as login1psyrgD35mMeta } from "/home/runner/work/rootle/rootle/client/src/pages/auth/login.vue?macro=true";
import { default as logoutwIkRXbWZqKMeta } from "/home/runner/work/rootle/rootle/client/src/pages/auth/logout.vue?macro=true";
import { default as registerZe6ssYVdpxMeta } from "/home/runner/work/rootle/rootle/client/src/pages/auth/register.vue?macro=true";
import { default as reset_45passwordvbYAJcmlNaMeta } from "/home/runner/work/rootle/rootle/client/src/pages/auth/reset-password.vue?macro=true";
import { default as successouPe7gxZcKMeta } from "/home/runner/work/rootle/rootle/client/src/pages/auth/success.vue?macro=true";
import { default as delete_45accountRcQWW0FaP0Meta } from "/home/runner/work/rootle/rootle/client/src/pages/confirm/delete-account.vue?macro=true";
import { default as email_45changejNkFhI0Yt6Meta } from "/home/runner/work/rootle/rootle/client/src/pages/confirm/email-change.vue?macro=true";
import { default as invitationfvL99DZcmrMeta } from "/home/runner/work/rootle/rootle/client/src/pages/confirm/invitation.vue?macro=true";
import { default as registration0YAZWr3MiWMeta } from "/home/runner/work/rootle/rootle/client/src/pages/confirm/registration.vue?macro=true";
import { default as indexAelWIO3sLFMeta } from "/home/runner/work/rootle/rootle/client/src/pages/index.vue?macro=true";
import { default as pricingpZ7O1QJVdDMeta } from "/home/runner/work/rootle/rootle/client/src/pages/pricing.vue?macro=true";
import { default as indexl7RseEyy6vMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/analytics/index.vue?macro=true";
import { default as indexEGTrcV0BBXMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/index.vue?macro=true";
import { default as appstIS2bOkZnOMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/apps.vue?macro=true";
import { default as automationsKKijtgQOWnMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/automations.vue?macro=true";
import { default as channelseCaBZuKtDkMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/channels.vue?macro=true";
import { default as members63OAni6fGWMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/members.vue?macro=true";
import { default as order_45documentsr1achcptXSMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/order-documents.vue?macro=true";
import { default as settingsIzq4xnf5SpMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/settings.vue?macro=true";
import { default as templatesvxCB9ffYjAMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/templates.vue?macro=true";
import { default as manageaWyqQs2R4hMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage.vue?macro=true";
import { default as indexVGEgdffuSSMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/messages/index.vue?macro=true";
import { default as invoice2AMIcMJmVSMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/orders/invoice.vue?macro=true";
import { default as salesCnrDeWqwLkMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/orders/sales.vue?macro=true";
import { default as indexpNt5GfgA8gMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/product/index.vue?macro=true";
import { default as indexYCex8V5rmUMeta } from "/home/runner/work/rootle/rootle/client/src/pages/w/index.vue?macro=true";
import { default as component_45stuba96MghAKV2Meta } from "/home/runner/work/rootle/rootle/client/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stuba96MghAKV2 } from "/home/runner/work/rootle/rootle/client/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountCAdEA4fxdXMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: "account-billing",
    path: "billing",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: "account-profile",
    path: "profile",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: "account-security",
    path: "security",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "settings",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-workspaces",
    path: "workspaces",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/account/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "admin",
    path: "/admin",
    meta: adminFcI9I07q5PMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-cronjobs",
    path: "cronjobs",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/admin/cronjobs.vue").then(m => m.default || m)
  },
  {
    name: "admin-explorer",
    path: "explorer",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/admin/explorer.vue").then(m => m.default || m)
  },
  {
    name: "admin-usage",
    path: "usage",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/admin/usage.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/admin/users.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "auth-apps-dropbox",
    path: "/auth/apps/dropbox",
    meta: dropboxOYLR73XTYaMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/auth/apps/dropbox.vue").then(m => m.default || m)
  },
  {
    name: "auth-failure",
    path: "/auth/failure",
    meta: failurep35BSj4buDMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/auth/failure.vue").then(m => m.default || m)
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordvYvNoCkFHYMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: login1psyrgD35mMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutwIkRXbWZqKMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerZe6ssYVdpxMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/auth/register.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: reset_45passwordvbYAJcmlNaMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/auth/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-success",
    path: "/auth/success",
    meta: successouPe7gxZcKMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/auth/success.vue").then(m => m.default || m)
  },
  {
    name: "confirm-delete-account",
    path: "/confirm/delete-account",
    meta: delete_45accountRcQWW0FaP0Meta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/confirm/delete-account.vue").then(m => m.default || m)
  },
  {
    name: "confirm-email-change",
    path: "/confirm/email-change",
    meta: email_45changejNkFhI0Yt6Meta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/confirm/email-change.vue").then(m => m.default || m)
  },
  {
    name: "confirm-invitation",
    path: "/confirm/invitation",
    meta: invitationfvL99DZcmrMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/confirm/invitation.vue").then(m => m.default || m)
  },
  {
    name: "confirm-registration",
    path: "/confirm/registration",
    meta: registration0YAZWr3MiWMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/confirm/registration.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexAelWIO3sLFMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingpZ7O1QJVdDMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "w-workspace-analytics",
    path: "/w/:workspace()/analytics",
    meta: indexl7RseEyy6vMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "w-workspace",
    path: "/w/:workspace()",
    meta: indexEGTrcV0BBXMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/index.vue").then(m => m.default || m)
  },
  {
    name: "w-workspace-manage",
    path: "/w/:workspace()/manage",
    meta: manageaWyqQs2R4hMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage.vue").then(m => m.default || m),
    children: [
  {
    name: "w-workspace-manage-apps",
    path: "apps",
    meta: appstIS2bOkZnOMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/apps.vue").then(m => m.default || m)
  },
  {
    name: "w-workspace-manage-automations",
    path: "automations",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/automations.vue").then(m => m.default || m)
  },
  {
    name: "w-workspace-manage-channels",
    path: "channels",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/channels.vue").then(m => m.default || m)
  },
  {
    name: "w-workspace-manage-members",
    path: "members",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/members.vue").then(m => m.default || m)
  },
  {
    name: "w-workspace-manage-order-documents",
    path: "order-documents",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/order-documents.vue").then(m => m.default || m)
  },
  {
    name: "w-workspace-manage-settings",
    path: "settings",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/settings.vue").then(m => m.default || m)
  },
  {
    name: "w-workspace-manage-templates",
    path: "templates",
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/manage/templates.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "w-workspace-messages",
    path: "/w/:workspace()/messages",
    meta: indexVGEgdffuSSMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/messages/index.vue").then(m => m.default || m)
  },
  {
    name: "w-workspace-orders-invoice",
    path: "/w/:workspace()/orders/invoice",
    meta: invoice2AMIcMJmVSMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/orders/invoice.vue").then(m => m.default || m)
  },
  {
    name: "w-workspace-orders-sales",
    path: "/w/:workspace()/orders/sales",
    meta: salesCnrDeWqwLkMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/orders/sales.vue").then(m => m.default || m)
  },
  {
    name: "w-workspace-product",
    path: "/w/:workspace()/product",
    meta: indexpNt5GfgA8gMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/[workspace]/product/index.vue").then(m => m.default || m)
  },
  {
    name: "w",
    path: "/w",
    meta: indexYCex8V5rmUMeta || {},
    component: () => import("/home/runner/work/rootle/rootle/client/src/pages/w/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stuba96MghAKV2Meta?.name,
    path: "/login",
    component: component_45stuba96MghAKV2
  },
  {
    name: component_45stuba96MghAKV2Meta?.name,
    path: "/logout",
    component: component_45stuba96MghAKV2
  },
  {
    name: component_45stuba96MghAKV2Meta?.name,
    path: "/register",
    component: component_45stuba96MghAKV2
  },
  {
    name: component_45stuba96MghAKV2Meta?.name,
    path: "/kundenportal",
    component: component_45stuba96MghAKV2
  }
]