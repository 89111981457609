export enum UserStatus {
  REGISTERING = 'REGISTERING',
  OK = 'OK',
  DEACTIVATED = 'DEACTIVATED',
  DELETED = 'DELETED',
  INVITED = 'INVITED'
}

export enum ChannelType {
  EBAY = 'EBAY'
}

export enum UserWorkspaceStatus {
  OK = 'OK',
  INVITED = 'INVITED',
  DEACTIVATED = 'DEACTIVATED',
  DISABLED = 'DISABLED'
}

export enum UserRequest {
  ACTIVATION = 'ACTIVATION',
  RESET_PASSWORD = 'RESET_PASSWORD',
  INVITATION = 'INVITATION',
  EMAIL_CHANGE = 'EMAIL_CHANGE',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
}

export enum MessageFolders {
  'inbox' = 0,
  'sent' = 1,
  'trash' = 5,
  'archive' = 6
}

export const SENT_FOLDER = MessageFolders[MessageFolders.sent]
export const INBOX_FOLDER = MessageFolders[MessageFolders.inbox]
export const ARCHIVE_FOLDER = MessageFolders[MessageFolders.archive]