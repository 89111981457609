const title = 'Rootle - dein kostenfreies eBay CRM';

export function defaultHead() {
  return {
    title: 'Rootle - dein kostenfreies eBay CRM',
/*    htmlAttrs: {
      lang: $i18n.localeProperties.code,
      ...i18nHead.htmlAttrs
    },*/
    charset: 'utf-8',
    meta: [
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {
        hid: 'description',
        name: 'description',
        content: 'Mit rootle kannst du ganz einfach deine eBay-Anfragen, Nachrichten und Fälle beantworten. dein eBay CRM.'
      },
      {hid: 'og:title', name: 'og:title', content: title},
      {hid: 'og:site_name', name: 'og:site_name', content: 'Rootle'},
      {
        hid: 'apple-mobile-web-app-title',
        name: 'apple-mobile-web-app-title',
        content: title
      },
      {
        hid: 'og:description',
        name: 'og:description',
        content: 'Mit der kostenfreien eBay CRM Lösung von rootle kannst du deine eBay Anfragen bequem und schneller beantworten'
      },
/*      ...i18nHead.meta*/
    ],
    link: [
      {rel: 'icon', type: 'image/x-icon', href: '/favicon.ico'},
      {rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap'},
/*      ...i18nHead.link*/
    ]
  };
}