import {BaseRepository} from './base.repository';

export default class BillingRepository extends BaseRepository {
  async paymentOverview() {
    return await this.get('/payment/overview');
  }

  async createStripeSessionCheckout(planKey: string) {
    return await this.post('/payment/stripe/session/checkout', {
      body: {
        plan: planKey
      }
    });
  }

  async createStripeSessionPortal(action: string) {
    return await this.post(`/payment/stripe/session/portal/${action}`);
  }
}
