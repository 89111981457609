import RobotRepository from '../repositories/ai.repository';
import AnalyticsRepository from '../repositories/analytics.repository';
import AppsRepositoy from '../repositories/apps.repository';
import Automation from '../repositories/automation.repository';
import BillingRepository from '../repositories/billing.repository';
import ChannelsRepository from '../repositories/channels.repository';
import EBayRepository from '../repositories/eBay.repository';
import InvoicesRepository from '../repositories/invoice.repository';
import MarketplaceRepository from '../repositories/marketplace.repository';
import MessageTemplatesRepository from '../repositories/message-templates.repository';
import NewsletterRepository from '../repositories/newsletter.repository';
import NotesRepository from '../repositories/notes.repository';
import OrderDocuments from '../repositories/order-documents.repository';
import TranslationRepository from '../repositories/translation.repository';
import WorkspacesRepository from '../repositories/workspaces.repository';

interface Repositories {
  eBay: EBayRepository;
  templates: MessageTemplatesRepository;
  notes: NotesRepository;
  invoices: InvoicesRepository;
  workspaces: WorkspacesRepository;
  apps: AppsRepositoy;
  automation: Automation;
  orderDocuments: OrderDocuments;
  channels: ChannelsRepository;
  analytics: AnalyticsRepository;
  marketplace: MarketplaceRepository;
  newsletter: NewsletterRepository;
  translation: TranslationRepository;
  billing: BillingRepository;
  ai: RobotRepository;
}

export const $repositories = {
  eBay: new EBayRepository(),
  templates: new MessageTemplatesRepository(),
  notes: new NotesRepository(),
  invoices: new InvoicesRepository(),
  workspaces: new WorkspacesRepository(),
  apps: new AppsRepositoy(),
  automation: new Automation(),
  orderDocuments: new OrderDocuments(),
  channels: new ChannelsRepository(),
  analytics: new AnalyticsRepository(),
  marketplace: new MarketplaceRepository(),
  newsletter: new NewsletterRepository(),
  translation: new TranslationRepository(),
  billing: new BillingRepository(),
  ai: new RobotRepository(),
};