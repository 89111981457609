export const useAuthStore = defineStore('authStore', {
  state: () => ({
    accessToken: null as null | string
  }),

  actions: {
    setAccessToken(accessToken: string | null) {
      this.accessToken = accessToken;
    },

    async register(data: any): Promise<any> {
      const {user, auth, confirmed} = await $api('/auth/register', {method: 'post', body: data}) as any;

      const rootStore = useRootStore();
      rootStore.resetAll();

      if (auth) {
        this.setAccessToken(auth.access_token);
      }

      if (user) {
        rootStore.setUser(user);
      }

      return {
        confirmed
      };
    },

    async login({password, email, keepMeLoggedIn}: any): Promise<any> {
      const {user, access_token} = await $api('/auth/session/login', {
        method: 'post',
        body: {
          email,
          password,
          keepMeLoggedIn
        }
      }) as any;

      this.setAccessToken(access_token);
      useRootStore().setUser(user);
    },

    async logout(global = false) {
      await $api('/auth/session/logout', {
        method: 'post',
        params: {
          global
        },
        credentials: 'include'
      });

      useRootStore().resetAll();
    },

    async refreshToken() {
      try {
        const headers = useRequestHeaders(['cookie'])
        const response = await $api('/auth/session/refresh', {method: 'post', headers }) as any;
        this.setAccessToken(response.access_token);
        return true;
      } catch (e) {
        console.error(e)
        this.setAccessToken(null);
        return false;
      }
    },

    async activateUser({token}: any): Promise<any> {
      return $api(`/user/confirm/registration/${token}`, {method: 'post'});
    },

    async resendActivationLink({email}: any): Promise<any> {
      return $api('/auth/register/resend-activation-link', {
        method: 'post',
        body: {
          email
        }
      });
    },

    async sendResetLink(email: string): Promise<any> {
      return $api('/auth/password/send-reset-link', {
        method: 'post',
        body: {
          email
        }
      });
    },

    async resetPassword({password, token}: any): Promise<any> {
      return $api('/auth/password/reset', {
        method: 'post',
        body: {
          password,
          token
        }
      });
    }
  }
});
