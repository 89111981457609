export enum ErrorCodes {
  LOGIN_FAILED = 5000,
  REGISTER_FAILED,
  MISSING_CREDENTIAL,
  LOGGED_IN_USER_DOES_NOT_EXIST,
  REFRESH_TOKEN_INVALID,

  USER_IS_DEACTIVATED,
  USER_IS_NOT_REGISTERING,
  USER_IS_REGISTERING,
  USER_DOES_NOT_EXIST,
  USER_PASSWORD_TOO_SHORT,
  USER_NAME_TOO_SHORT,
  USER_EMAIL_REQUIRED,

  USER_ALREADY_EXISTS,
  USER_REQUEST_DOES_NOT_EXIST,
  USER_REQUEST_RESOLVED,

  EBAY_INVALID_TOKEN = 6000,
  EBAY_API_TOKEN_REQUIRED,
  EBAY_API_LIMIT_REACHED,
  EBAY_CHANNEL_INVALID,
  EBAY_CONTACT_IN_EMAIL_NOT_ALLOWED,
  EBAY_JPEG_BELOW_90,
  EBAY_UPLOAD_ERROR,
  EBAY_API_ERROR,
  EBAY_WORKSPACE_CHANNEL_REQUIRED,
  EBAY_TRACKING_CODE_ALREADY_EXISTS,
  EBAY_REFUND_AMOUNT_EXCEEDS_ORDER_AMOUNT,
  REFUND_CANT_BE_ISSUED_WHILE_PREVIOUS_REFUND_IS_PROCESSING,

  WORKSPACE_IS_REQUIRED,
  WORKSPACE_PARAMETER_IS_REQUIRED,
  WORKSPACE_ALREADY_EXIST,
  WORKSPACE_NAME_INVALID,
  WORKSPACE_LIMIT_REACHED,
  WORKSPACE_USER_ALREADY_IN_WORKSPACE,
  WORKSPACE_USER_ALREADY_INVITED,


  CHANNEL_IS_REQUIRED = 7000,
  CHANNEL_PARAMETER_IS_REQUIRED,

  THREAD_USERNAME_REQUIRED = 8000,
  THREAD_EBAY_USER_DOES_NOT_EXISTS,

  INTERNAL_SERVER_ERROR = 10000,
  RUNTIME_ERROR = 11000,
  UNHANDLED_ERROR = 20000,
  RESOURCE_NOT_FOUND = 30000,
  INVALID_INPUT_ERROR,
  INTEGRITY_CONSTRAINT_VIOLATION_ERROR,

  ORDER_DOCUMENTS_INVOICE_MISSING = 40000,
  ORDER_DOCUMENTS_NUMBER_GROUP_USED,

  AUTOMATION_ODER_DOCUMENT_REQUIRED = 50000,
  AUTOMATION_NO_TASKS
}
