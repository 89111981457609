export abstract class BaseRepository {

  constructor(private readonly fatal = false) {
  }

  public get setup() {
    // @ts-ignore
    return new this.constructor(true);
  }

  private async request<T>(url: string,
                       opts: Parameters<typeof $fetch<T>>[1] = {}) {
    return await useAPI<T>(url, {fatal: this.fatal, ...opts});
  }

  public async get<T>(url: string,
                       opts?: Parameters<typeof $fetch<T>>[1]) {
    return await this.request(url, opts);
  }

  public async post<T>(url: string,
                       opts: Parameters<typeof $fetch<T>>[1] = {}) {
    return await this.request(url, {...opts, method: 'post'});
  }

  public async patch<T>(url: string,
                       opts: Parameters<typeof $fetch<T>>[1] = {}) {
    return await this.request(url, {...opts, method: 'patch'});
  }

  public async put<T>(url: string,
                       opts: Parameters<typeof $fetch<T>>[1] = {}) {
    return await this.request(url, {...opts, method: 'put'});
  }

  public async delete<T>(url: string,
                       opts: Parameters<typeof $fetch<T>>[1] = {}) {
    return await this.request(url, {...opts, method: 'delete'});
  }

}