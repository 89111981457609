
export default function () {
  const {$log} = useNuxtApp()

  return {
    capture(ex: unknown) {
      $log.error(ex)
    },
    ...$log
  }
}