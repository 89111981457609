import {ARCHIVE_FOLDER, INBOX_FOLDER} from '../enums';

export type MessageFolder = {
  id: string
  name: string
  newMessageCount: number
}

export const basicFolders = () : MessageFolder[] => [{
  id: INBOX_FOLDER,
  name: 'Offen',
  newMessageCount: 0
}, {
  id: ARCHIVE_FOLDER,
  name: 'Erledigt',
  newMessageCount: 0
}];