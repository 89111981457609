import {ErrorCodes} from '@shared';

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig();
  const route = useRoute();
  const rootStore = useRootStore();
  const authStore = useAuthStore();
  const router = useRouter();
  const api = $fetch.create({
    baseURL: `${config.apiUrl || config.public.apiUrl}/api/v1`,
    credentials: config.public.stage === 'dev-staging' ? 'include' : 'same-origin',
    onRequest(context): Promise<void> | void {
      const workspace = rootStore.workspace;
      const channel = rootStore.channel;
      const requestUrl = context.request as string;

      context.options.headers = new Headers(context.options.headers);

      if (process.server) {
        const reqeustHeaders = useRequestHeaders(['cookie']);
        if (reqeustHeaders.cookie) {
          context.options.headers.set('Cookie', reqeustHeaders.cookie);
        }
      }

      const accessToken = authStore.accessToken;
      if (accessToken) {
        context.options.headers.set('Authorization', `Bearer ${accessToken}`);
      }

      context.request = passUrlParameters(requestUrl, {
        ':channel': channel?.id,
        ':workspace': workspace?.id,
      });

      const {$log} = nuxtApp;
      $log.debug('Make request', context.request);

      // Pass query params
      context.options.params = {
        ...context.options.params,
        ...passQueryParams(['refresh'], route.query),
      };
    },

    async onRequestError(context): Promise<any> {
      const {$log} = nuxtApp;
      $log.error(context.error);
    },

    async onResponseError(context): Promise<any> {
      const {$log} = nuxtApp;
      const url = typeof context.request === 'string' ? context.request : ''; // TODO
      $log.debug('onResponseError', url);

      if (context.error) {
        $log.error(context.error);
        return;
      }

      const {status} = context.response;
      const accessToken = authStore.accessToken;
      const isTrialUser = rootStore.isTrialUser;
      const is401 = status === 401;

      if (is401 && url.includes('/login')) {
        $log.debug('Response status 401 on /login -> nothing todo.');
        return;
      } else if (accessToken && is401 && !url.includes('/refresh')) {
        const refreshed = await authStore.refreshToken();
        if (refreshed) {
          return await api(context.request, {
            // @ts-ignore
            method: context.options.method,
            body: context.options.body,
            headers: context.options.headers,
            params: context.options.params
          });
        } else {
          $log.debug('Refresh token was not successful. Redirect to /logout.', url);
          await router.push('/auth/logout');
          return;
        }
      } else if (is401) {
        $log.debug('Response status 401. Refresh token was not successful.', url);
        await router.push('/auth/logout');
        return;
      }

      const workspace = rootStore.workspace;
      const channel = rootStore.channel;

      const data = context.response._data;
      if (!data?.code) {
        $log.error('Error occurred in $api fetch. No AppErrorCode provided. ' + (data.message || ''), url, {
          workspaceId: workspace?.id,
          channelId: channel?.id
        });
        return;
      }

      switch (data.code) {
        case ErrorCodes.EBAY_API_TOKEN_REQUIRED:
        case ErrorCodes.EBAY_INVALID_TOKEN:
          if (isTrialUser) {
            await router.push('/logout');
            return;
          }

          await router.push('/w/' + workspace.slug + '/manage/channels?expired=' + channel?.slug);
          return;
        case ErrorCodes.WORKSPACE_LIMIT_REACHED:
          rootStore.setShowUpgrade(true);
          return;
        case ErrorCodes.EBAY_API_LIMIT_REACHED:
          rootStore.setEBayShowLimitReached(true);
          return;

        default:
          $log.error(context.error);
      }
    }
  });

  // Inject to context as $api
  return {
    provide: {
      api
    }
  };
});
