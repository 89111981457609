/*import {money} from '@rootle/shared';
import {displayDate, displayDateAndTime} from '@rootle/shared';*/

export default defineNuxtPlugin(() => {
  return {
    provide: {
      money: (v: any) => v,
      date: (v: any) => v ,
      dateTime: (v: any) => v,
      locale: 'de-DE' // TODO
    }
  };
});