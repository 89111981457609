import {type Channel, COOKIE_NAME_DATA, Plan, PlanDefinition, PLANS, type Workspace} from '@shared';

type Current = {
  page: string,
  workspace: number | null,
  channel: string | null
}

interface RootStoreState {
  fresh: boolean,
  current: Current,
  user: any,
  _marketplace: any

  // Modals
  showUpgrade: boolean
  showEBayLimitReached: boolean
  showTrialModal: boolean
}

export const useRootStore = defineStore('rootStore', {
  state: (): RootStoreState => ({
    fresh: true,
    current: {
      page: 'index',
      workspace: null,
      channel: null
    },
    user: null,
    _marketplace: null, //TODO loaded twice

    showUpgrade: false,
    showEBayLimitReached: false,
    showTrialModal: false
  }),

  getters: {
    workspaces(state): any {
      return state.user?.workspaces ?? {};
    },
    workspace(state): any {
      return this.workspaces[state.current.workspace || -1];
    },
    channels(): any {
      return this.workspace?.channels ?? {};
    },
    channel(state): any {
      if (!state.current.channel) {
        return null;
      }

      return this.channels[state.current.channel] ?? null;
    },
    marketplace(state) {
      if (!state.current.channel || state.current.channel !== state._marketplace?.channel?.id) {
        return null;
      }

      return state._marketplace;
    },

    isTrialUser() {
      return !this.isLoggedIn;
    },
    isLoggedIn(state): boolean {
      return !!state.user?.id;
    },
    isFree(): boolean {
      return this.plan === 'FREE'
    },
    isFreeOrTrial(): boolean {
      return this.isTrialUser || this.isFree
    },
    isBasic(): boolean {
      return this.plan === 'BASIC'
    },
    isPremium(): boolean {
      return this.plan === 'PREMIUM'
    },
    plan(state): Plan {
      return this.workspace?.plan || state.user.plan || 'TRIAL'
    },
    planLimit(): PlanDefinition {
      return PLANS[this.plan]
    },
    isSuperAdmin(state): boolean {
      return state.user?.admin;
    },
  },

  actions: {
    setCurrentPage(page: string) {
      this.current.page = page;
    },

    setCurrentChannel(channelId: string) {
      this.current.channel = channelId;
    },

    setCurrentWorkspace(workspace: number) {
      this.current.workspace = workspace;
    },

    setUser(user: object) {
      this.user = user;
    },

    setMarketplace(marketplace: object) {
      this._marketplace = marketplace;
    },

    addWorkspace(workspace: any) {
      this.user.workspaces[workspace.id] = workspace;
    },

    deleteWorkspace(workspacesId: any) {
      delete this.user.workspaces[workspacesId];
    },

    setWorkspaceSettings({workspace, settings}: any) {
      this.user.workspaces[workspace].settings = JSON.parse(JSON.stringify(settings));
    },

    setWorkspaceAttr({workspace, key, value}: any) {
      this.user.workspaces[workspace][key] = value;
    },

    deleteChannel({workspace, channel}: any) {
      delete this.user.workspaces[workspace].channels[channel];
    },

    setChannelAttr({workspace, channel, key, value}: any) {
      this.user.workspaces[workspace].channels[channel][key] = value;
    },

    setFresh(fresh: boolean) {
      this.fresh = fresh;
    },

    setShowUpgrade(show: boolean) {
      this.showUpgrade = show;
    },

    setEBayShowLimitReached(show: boolean) {
      this.showEBayLimitReached = show;
    },

    resetAll() {
      this.$reset();
      useFoldersStore().reset();
      useTemplateStore().reset();
    },

    async fetchUser() {
      try {
        const data = await $api('/auth/session/user') as any;
        const {user} = data;

        this.setFresh(true);
        this.setUser(user);
      } catch (e) {
        const {capture} = useLog();
        capture(e);
      }
    },

    async fetchMarketplace() {
      if (!this.workspace || !this.channel) {
        return;
      }

      try {
        const {marketplace} = await $repositories.marketplace.getMarketplace(this.workspace.id, this.channel.id) as any;
        this.setMarketplace(marketplace);
      } catch (e) {
        const {capture} = useLog();
        capture(e);
      }
    },

    async fetchSession({route}: any): Promise<any> {
      if (!this.user) {
        return;
      }

      const {workspace, channel} = getWorkspaceAndChannel(this.user, this.current, route, this.workspaces);

      const switchContext = workspace && workspace.id !== this.current.workspace
        || channel && channel.id !== this.current.channel;

      this.setCurrentWorkspace(workspace?.id || null);
      this.setCurrentChannel(channel?.id || null);

      if (!this.fresh && !switchContext) {
        return;
      }

      await this.fetchMarketplace();
      this.setFresh(false);
    },

    async authEBay({code, workspace: workspaceId, channel: channelId}: any): Promise<any> {
      const {workspace, channel} = await $api('/ebay/login/authorize', {
        body: {
          code,
          workspaceId,
          channelId,
        },
        method: 'post'
      }) as any;

      await this.fetchUser();

      return {
        workspace,
        channel
      };
    }
  }
});

export const getWorkspaceAndChannel = (user: any, current: any, route: any, workspaces: any) => {
  const dataCookie = useCookie(COOKIE_NAME_DATA).value?.[user.id];
  const lastUsed = user?.id && dataCookie || {};

  const workspaceId = route.params.workspace?.split('-').slice(-1)
    || user?.workspaces?.[current.workspace || -1]?.id
    || user?.workspaces?.[lastUsed.workspaceId || -1]?.id
    || (Object.values(workspaces) as Workspace[])[0]?.id;

  const workspace = workspaceId && user?.workspaces?.[workspaceId];

  const channelId = route.query.channel?.split('-').slice(-1)
    || workspace?.channels?.[current.channel || -1]?.id
    || workspace?.channels?.[lastUsed.channelId || -1]?.id
    || (Object.values(workspace?.channels || {}) as Channel[])[0]?.id;

  const channel = channelId && workspace?.channels?.[channelId];

  return {
    workspace,
    channel
  };
};
