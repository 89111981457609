export const useTemplateStore = defineStore('templateStore', {
  state: () => ({
    workspace: null as null | any,
    templates: [] as any[],
    categories: [] as any[]
  }),

  getters: {
    templatesByCategoryId(state) {
      return state.templates
        .filter(template => template.category_id !== null)
        .reduce((result, template) => {
          const templates = result[template.category_id] || [];
          templates.push(template);
          result[template.category_id] = templates;
          return result;
        }, {});
    },

    categoriesById(state) {
      return state.categories
        .reduce((result, category) => {
          result[category.id] = category;
          return result;
        }, {});
    },

    templatesAndCategories(state): any {
      const templatesByCategoryId = this.templatesByCategoryId;

      const templates = state.templates.filter(template => template.category_id === null);

      const categories = state.categories.map(category => {
        return {
          ...category,
          templates: templatesByCategoryId[category.id] || []
        };
      })
        .filter(category => category.templates.length > 0);

      return {
        templates,
        categories
      };
    }
  },

  actions: {
    reset() {
      this.$reset();
    },

    setTemplates({templates, workspace, categories}: any) {
      this.templates = templates;
      this.workspace = workspace;
      this.categories = categories;
    },

    removeTemplate(id: number) {
      const index = this.templates.findIndex(t => t.id === id);
      if (index > -1) {
        this.templates.splice(index, 1);
      }
    },

    addTemplate(template: any) {
      this.templates.push(template);
    },

    // Categories
    addCategory(category: any) {
      this.categories.push(category);
    },

    removeCategory(id: number) {
      const index = this.categories.findIndex(t => t.id === id);
      if (index > -1) {
        this.categories.splice(index, 1);
      }
    },

    updateTemplate(template: any) {
      const index = this.templates.findIndex(t => t.id === template.id);
      if (index > -1) {
        this.templates.splice(index, 1, template);
      }
    },

    updateCategory(category: any) {
      const index = this.categories.findIndex(t => t.id === category.id);
      if (index > -1) {
        this.categories.splice(index, 1, category);
      }
    },

    setCategories(categories: any) {
      this.categories = categories;
    },

    updateCategoriesPosition(categories: any) {
      this.categories = categories.map((c: any, position: any) => ({
        ...c,
        position,
      }));
    },

    async loadTemplates(workspace: number) {
      if (!useRootStore().user?.id || !workspace || workspace === this.workspace) {
        return;
      }

      const result = await $repositories.templates.getTemplates();
      this.setTemplates({
        workspace: workspace,
        templates: result.templates,
        categories: result.categories
      });
    },

    async loadTemplate(id: number): Promise<any> {
      const result = await $repositories.templates.getTemplate(id);

      return result.template;
    },

    async deleteTemplate(id: number) {
      await $repositories.templates.deleteTemplate(id);

      this.removeTemplate(id);
    },

    async createTemplate({template, name, category}: any) {
      const result = await $repositories.templates.addTemplate(template, name, category);

      this.addTemplate(result.template);
    },

    async changeTemplate({id, template, name, category}: any) {
      const result = await $repositories.templates.updateTemplate(id, template, name, category);

      this.updateTemplate(result.template);
    },

    // Categories
    async loadCategory(id: number): Promise<any> {
      const result = await $repositories.templates.getCategory(id);

      return result.category;
    },

    async createCategory({name, settings}: any) {
      const result = await $repositories.templates.addCategory(name, settings);

      this.addCategory(result.category);
    },

    async deleteCategory(id: number) {
      await $repositories.templates.deleteCategory(id);

      this.removeCategory(id);
    },

    async changeCategory({id, name, settings}: any) {
      const result = await $repositories.templates.updateCategory(id, name, settings);

      this.updateCategory(result.category);
    },

    async changeCategoryPosition({id, position}: { id: number, position: number }) {
      const result = await $repositories.templates.changeCategoryPosition(id, position);

      this.setCategories(result.categories);
    }
  }

});
