import {WORKSPACE_PATH} from '@shared';
import {BaseRepository} from './base.repository';

export default class ChannelsRepository extends BaseRepository {
  async changeChannel(workspaceId: number, channelId: number, data: any[]) {
    return await this.patch(`/workspaces/${workspaceId}/channels/${channelId}`, {
      body: data
    });
  }

  async deleteChannel(workspaceId: number, channelId: number) {
    return await this.delete(`/workspaces/${workspaceId}/channels/${channelId}`);
  }

  async setChannelOrderInvoiceDocument(channelId: number, layoutId: number, type: string, numberGroupId: number) {
    return await this.put(`${WORKSPACE_PATH}/channels/${channelId}/order-documents`, {
      body: {
        type,
        layoutId,
        numberGroupId
      }
    });
  }

  async deleteChannelOrderInvoiceDocument(channelId: number, type: string) {
    return await this.delete(`${WORKSPACE_PATH}/channels/${channelId}/order-documents`, {
      body: {
        type
      }
    });
  }

  async getOrderDocuments(channelId: number) {
    return await this.get(`${WORKSPACE_PATH}/channels/${channelId}/order-documents`);
  }
}
