import {COOKIE_NAME_DATA} from '@shared';

const initAuth = {
  admin: false, // admin only
  workspace: true,
  channel: true,
  public: false,
  trial: false // Allow trial
};

function storeLastUsed(user: any, {workspace, channel}: any) {
  if (!workspace || !user?.id) {
    return;
  }

  const cookie = useCookie(COOKIE_NAME_DATA, {
    path: '/',
    maxAge: 60 * 60 * 24 * 7,
  });

  cookie.value = JSON.stringify({
    [user.id]: {
      workspaceId: workspace.id,
      channelId: channel?.id
    },
  });
}

const isIgnorePath = (path: string): boolean => {
  return path.startsWith('/socket.io') ||
    path.startsWith('/api/v1/') ||
    path.includes('/login') ||
    path.includes('/logout');
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  const {$log} = useNuxtApp();

  if (isIgnorePath(to.fullPath)) {
    $log.debug('Auth ignore request ' + to.fullPath)
    return;
  }

  $log.debug('Auth handle request ' + to.fullPath)


  const rootStore = useRootStore();
  await rootStore.fetchSession({route: to});

  const metaAuth = to.meta.auth || initAuth;

  // current user is not required at all
  if (metaAuth === false) {
    return;
  }

  const auth = {...initAuth, ...metaAuth};
  const user = rootStore.user;



  if (!user && !auth.public) {
    $log.debug('Redirect: not public', to.fullPath);
    return navigateTo('/login');
  } else if (auth.public) {
    return;
  }

  storeLastUsed(rootStore.user, {
    workspace: rootStore.workspace,
    channel: rootStore.channel
  });

  const workspace = rootStore.workspace;
  const channel = rootStore.channel;

  if (workspace && to.params.workspace && workspace.slug !== to.params.workspace) {
    $log.debug('Redirect: wrong workspace path', to.params.workspace + '<->' + workspace.slug);
    const path = to.fullPath.substring(('/w/' + to.params.workspace).length);
    return navigateTo('/w/' + workspace.slug + (path === '/' ? '' : path));
  }

  const params = {
    query: {
      ...to.query
    }
  };

  if (!auth.trial && !user.id) {
    $log.debug('Redirect: trial not allowed');
    return navigateTo({path: '/', ...params});
  }

  if (auth.admin && !user.admin) {
    $log.debug('Redirect: admin required');
    return navigateTo({path: '/', ...params});
  }

  if ((auth.workspace || auth.channel) && !workspace) {
    $log.debug('Redirect: workspace required');
    return navigateTo({path: '/account/workspaces', ...params});
  }

  if (auth.channel && !channel) {
    $log.debug('Redirect: channel required');
    return navigateTo({path: `/w/${workspace.slug}/manage/channels`, ...params});
  }

  if (auth.channel && channel && !to.query.channel) {
    $log.debug('Redirect: add channel to query', channel.slug);
    return navigateTo({
      path: to.path,
      query: {
        ...to.query,
        channel: channel.slug
      }
    });
  }
});
