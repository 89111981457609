export const passUrlParameters = (url: string, urlParams: { ':workspace': any; ':channel': any }) => {
  let urlWithParams = url;

  if (urlWithParams) {
    Object.entries(urlParams).forEach(([key, value]) => {
      if (urlWithParams.includes(key)) {
        if (!value) {
          throw new Error('passUrlParameters -- Url param is required');
        }

        urlWithParams = urlWithParams.replaceAll(key, value);
      }
    });
  }

  return urlWithParams;
};

export const passQueryParams = (params: string[], query: any) => {
  if (params.length === 0 || !query) {
    return {}
  }
  return params.reduce((result: any, param) => {
    if (typeof query[param] !== 'undefined') {
      result[param] = query[param];
    }

    return result;
  }, {});
};