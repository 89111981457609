<template>
  <a-modal
      :title="$t('plan.limitReached.title')"
      type="info"
      :zIndex="1001"
      v-model:open="showUpgrade"
  >
    <p>{{ $t('plan.limitReached.p1') }}</p>
    <p v-html="$t('plan.limitReached.p2')"/>
    <template #footer>
      <a-button @click="showUpgrade=false">{{ $t('common.ok') }}</a-button>
      <a-button @click="upgrade" type="primary">Jetzt upgraden</a-button>
    </template>
  </a-modal>
  <a-modal
      title="Rootle - eBay-Nutzungslimit erreicht :("
      type="info"
      v-model:open="showEBayLimitReached"
      :zIndex="1001"
      :footer="null"
  >
    <p>Durch die erhöhte Anzahl an rootle-Nutzern wurde leider unser eBay-Nutzungslimit erreicht.</p>
    <p>Wir bedauern die Unannehmlichkeiten sehr und sind bemüht so schnell wie möglich unser Nutzungslimit bei eBay
      zu
      erweitern, damit Sie wieder uneingeschränkt alle rootle-Funktionen nutzen können.</p>
    <p>Vielen Dank für Ihr Verständnis.</p>
    <p>Dein rootle-Team</p>
  </a-modal>
  <a-modal
      title="Rootle - Vorschauversion"
      type="info"
      :zIndex="1001"
      v-model:open="showTrialModal"
      :closable="false"
      @ok="showTrialModal = false"
      @cancel="navigateTo('/logout')"
      ok-text="Einverstanden & weiter"
      cancel-text="Ablehnen & abmelden"
  >
    <p>Willkommen in der Vorschau-Version von rootle!</p>
    <p>Die Vorschau hat beschränkte Funktionen. Alle Daten werden temporär abgespeichert und nach dem abmelden
      sofort gelöscht.</p>
    <p>In der Vorschau kannst du nur die Nachrichten anschauen und verschicken.</p>
    <p>Beim Verschicken von Nachrichten wird <em>"Versendet mit rootle.de"</em> angehängt.</p>
    <p>Registriere dich kostenlos, um mehr Funktionen freizuschalten.</p>
    <p><br>Dein rootle-Team</p>
  </a-modal>
</template>
<script lang="ts" setup>
const rootStore = useRootStore();
const {showTrialModal, showUpgrade, showEBayLimitReached} = storeToRefs(rootStore);

async function upgrade() {
  showUpgrade.value = false;
  await navigateTo('/account/billing');
}
</script>