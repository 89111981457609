import {basicFolders, EBAY_PATH, type MessageHeader} from '@shared';

export const useFoldersStore = defineStore('foldersStore', {
  state: () => ({
    folders: basicFolders(),
    currentFolder: 'inbox',

    loadingList: true,
    hasMore: true,
    list: [] as any[],
    listIndexMap: {} as any,
    pageNumber: 1,
    selected: null
  }),

  actions: {
    reset() {
      this.$reset()
    },

    setList({list, folder, pageNumber}: { list: any[], folder: string, pageNumber: number }) {
      if (folder !== this.currentFolder ||
        (folder === this.currentFolder && pageNumber === this.pageNumber)) {
        this.list = list;
      } else {
        this.list = this.list.concat(list);
      }

      const listIndexMap: any = {};
      this.list.forEach((group, index) => {
        group.messages.forEach((message: MessageHeader) => {
          listIndexMap[message.id] = index;
        });
      });

      this.listIndexMap = listIndexMap;
      this.currentFolder = folder;
      this.hasMore = list.length > 0;
      this.loadingList = false;
      this.pageNumber = pageNumber;
    },

    setCurrentFolder(folder: string) {
      this.currentFolder = folder;
    },

    setFolders(folders: any) {
      this.folders = folders;
    },

    setPageNumber(pageNumber: number) {
      this.pageNumber = pageNumber;
    },

    setMessageFolder({folder, ids}: { folder: string, ids: string[] }) {
      ids.forEach(id => {
        const listIndex = this.listIndexMap[id];
        if (typeof listIndex !== 'undefined') {
          this.list[listIndex].folder = folder;
        }
      });
    },

    loadingFolderState({
                         loading,
                         folder,
                         reset
                       }: { loading: boolean, folder: string, reset: boolean }) {
      this.loadingList = loading;
      this.currentFolder = folder;

      if (reset) {
        this.list = [];
        this.hasMore = true;
        this.listIndexMap = {};
      }
    },

    updateNewMessageCount(value: boolean) {
      const folderIndex = this.folders.findIndex((folder: any) => folder.id === this.currentFolder);
      if (folderIndex === -1) {
        return;
      }

      const newMessageCount = this.folders[folderIndex].newMessageCount;
      this.folders[folderIndex].newMessageCount = value ? newMessageCount - 1 : newMessageCount + 1;
    },

    setMessageState({ids, key, value}: { ids: string[], key: string, value: boolean }) {
      ids.forEach(id => {
        const listIndex = this.listIndexMap[id];
        if (typeof listIndex === 'undefined') {
          return;
        }

        const messages = this.list[listIndex].messages;
        const lIndex = messages.findIndex((message: any) => message.id === id);
        if (lIndex === -1) {
          return;
        }

        if (key === 'read' && this.list[listIndex].messages[lIndex]['read'] !== value) {
          this.updateNewMessageCount(value);
        }

        this.list[listIndex].messages[lIndex][key] = value;
      });
    },

    selectMessage(selected: any) {
      this.selected = selected;
    },

    locked({userId, locked}: any) {
      this.list.forEach((group: any) => {
        if (locked[group.id] && locked[group.id].userId !== userId) {
          group.locked = locked[group.id];
        } else {
          group.locked = null;
        }
      });
    },

    lockState({threadId, locked}: any) {
      const index = this.list.findIndex((list: any) => {
        return list.id === threadId;
      });

      if (index !== -1) {
        this.list[index].locked = locked;
      }
    },

    async fetchFolders() {
      try {
        const response = await useAPI(`${EBAY_PATH}/messages/folders`) as any;
        this.setFolders(response.folders);
      } catch (error) {
        const {capture} = useLog();
        capture(error);
      }
    },

    selectMessageFromThread() {
      // Select messages
      const thread = useThreadStore().thread;
      if (thread && !this.selected) {
        const lastMessage = thread.messages[thread.messages.length - 1];
        if (lastMessage) {
          this.selectMessage(lastMessage.id);
        }
      }
    },

    async fetchFolder({
                        folder,
                        pageNumber
                      }: { folder: string, pageNumber: number }) {
      const reset = (folder !== this.currentFolder);// || (folder === state.currentFolder && pageNumber === state.pageNumber);
      this.loadingFolderState({loading: true, folder, reset});

      if (reset) {
        await useThreadStore().closeThread();
      }

      try {
        const response = await useAPI(`${EBAY_PATH}/messages/folders/${folder}`, {
          params: {
            pageNumber
          }
        }) as any;

        const list: any = response.list;
        this.setFolders(response.folders);
        this.setList({
          folder,
          list,
          pageNumber
        });

        this.selectMessageFromThread();
      } catch (error) {
        const {capture} = useLog()
        capture(error);
      }
    }
  }
});