import {BaseRepository} from './base.repository';

export default class NewsletterRepository extends BaseRepository {
  async getSubscription() {
    return await this.get('/newsletter');
  }

  async subscribe() {
    return await this.post('/newsletter/subscribe');
  }

  async unsubscribe() {
    return await this.post('/newsletter/unsubscribe');
  }
}
