import {WORKSPACE_PATH} from '@shared';
import {BaseRepository} from './base.repository';

export default class AppsRepository extends BaseRepository {
  async getApps() {
    return await this.get(`${WORKSPACE_PATH}/apps`);
  }

  async deleteApp(appId: number) {
    await this.delete(`${WORKSPACE_PATH}/apps/${appId}`);
  }

  async connect(type: string, code: string, workspace: number) {
    if (type === 'DROPBOX') {
      await this.post(`/workspaces/${workspace}/apps/dropbox/authorize`, {
        body: {code}
      });
    }

    throw new Error('App type is not supported.');
  }

}

