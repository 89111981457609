<template>
  <NuxtLayout name="centered">
    <a-config-provider :locale="locale">
      <a-card>
        <h1 v-if="error.statusCode === 404">Seite wurde nicht gefunden.</h1>
        <h1 v-else>Ein Fehler ist aufgetretten :(</h1>
        <div style="margin: 20px 0; font-size: 22px; text-align: center">{{ error.statusCode }}</div>
        <nuxt-link to="/" @click="clearError" :prefetch="false">Zurück zum Dashboard</nuxt-link>
      </a-card>
      <GlobalModals/>
    </a-config-provider>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import locale from 'ant-design-vue/locale/de_DE';

useHead(defaultHead());

const error = useError();
</script>
<style lang="scss" module>
.centered {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
</style>
