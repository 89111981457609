export type PlanDefinition = {
  workspaces: number;
  channels: number;
  members: number;
  templates: number;
};

export type Plan = 'TRIAL' | 'FREE' | 'BASIC' | 'PREMIUM'

export type Plans = {
  [K in Plan]: PlanDefinition;
};
export const PLANS: Plans = {
  TRIAL: {
    workspaces: 0,
    channels: 0,
    members: 0,
    templates: 0,
  },
  FREE: {
    workspaces: 1,
    channels: 2,
    members: 2,
    templates: 5,
  },
  BASIC: {
    workspaces: 2,
    channels: 5,
    members: 5,
    templates: 20,
  },
  PREMIUM: {
    workspaces: 5,
    channels: 10,
    members: 10,
    templates: 100,
  },
};