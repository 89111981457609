import {EBAY_PATH} from '@shared';
import {ActivityOption} from 'ebay-api/lib/enums';

export const useReturnStore = defineStore('returnStore', {
  state: () => ({
    return: null as null | any,
    loading: false
  }),

  getters: {
    canReply(state): boolean {
      if (!state.return) {
        return false;
      }

      return !!state.return.actions.find((action: any) => action.id === ActivityOption.BUYER_SEND_MESSAGE || action.id === ActivityOption.SELLER_SEND_MESSAGE);
    }
  },

  actions: {
    reset() {
      this.$reset();
    },

    setReturn(returnData: any) {
      this.return = returnData;
      this.loading = false;
    },

    setLoading(loading: boolean) {
      this.loading = loading;
      this.return = null;
    },

    async fetchReturn(returnId: string): Promise<any> {
      this.setLoading(true);
      try {
        const response = await $api(`${EBAY_PATH}/return/${returnId}`) as any;
        this.setReturn(response.return);

        if (response.return.orderId) {
          await useOrdersStore().fetchOrder({orderId: response.return.orderId, refresh: false});
        }

      } catch (e) {
        this.setLoading(false);
        // $sentry.captureException(e);
      }
    },

    proceed({
              returnId,
              option,
              payload
            }: { returnId: string, option: string, payload: any }): Promise<any> {
      return $api(`${EBAY_PATH}/return/${returnId}/${option}`, {
        method: 'post',
        body: payload,
      });
    },

    async reply({text, returnId}: any): Promise<any> {
      const response = await $api(`${EBAY_PATH}/return/${returnId}/reply`, {
        method: 'post',
        body: {
          text,
          returnId,
        }
      }) as any;

      useThreadStore().addMessage(response.message);

      return response.message;
    }
  }
});