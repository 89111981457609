export const useCancelStore = defineStore('cancelStore', {
  state: () => ({
    cancel: null as null | unknown,
    loading: false
  }),

  actions: {
    reset() {
      this.$reset();
    },

    setLoading(loading: boolean) {
      this.cancel = null;
      this.loading = loading;
    },

    setCancel(cancel: any) {
      this.cancel = cancel;
      this.loading = false;
    },

    async fetchCancel(cancelId: string) {
      this.setLoading(true);
      try {
        const result = await $repositories.eBay.getCancellation(cancelId) as any;
        this.setCancel(result.cancel);
      } catch (error) {
        this.setLoading(false);
        const {capture} = useLog()
        capture(error);
      }
    },

    proceed({cancelId, option}: { cancelId: string, option: string }): Promise<any> {
      return $repositories.eBay.proceedCancellation(cancelId, option);
    },
  }
});
