const itemsCache = new Map()

export const useItemsStore = defineStore('itemsStore', {
  state: () => ({
    item: null as null | any,
    loading: false
  }),

  actions: {
    reset() {
      this.$reset();
    },

    setItem(item: any) {
      this.item = item;
      this.loading = false;
    },

    setLoading(loading: boolean) {
      this.item = null;
      this.loading = loading;
    },

    async fetchItem(itemId: string) {
      this.setLoading(true);
      try {
        const item = await this.getItem(itemId);
        this.setItem(item);
      } catch (ex) {
        const {capture} = useLog()
        this.setLoading(false);
        capture(ex);
      }
    },

    async getItem(itemId: string) {
      const item = itemsCache.get(itemId)
      if (item) {
        return item
      }

      const response = await $repositories.eBay.getItem(itemId) as any;
      itemsCache.set(itemId, response.item);
      return response.item;
    }
  }
});
