import {createConsola} from 'consola';

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()

  const level = config.public.stage === 'production' ? 3 : 4;
  const logger = createConsola({
    level,
  });

  return {
    provide: {
      log: logger
    }
  };
});
