export const WORKSPACE_PATH = '/workspaces/:workspace';
export const CHANNEL_PATH = '/channels/:channel';
export const WAC_PATH = WORKSPACE_PATH + CHANNEL_PATH;

export const EBAY_PATH = WAC_PATH + '/ebay';

export const COOKIE_SECRET = 'knorky';
export const COOKIE_TRIAL = '_rootle_trial';
export const COOKIE_AUTH_TOKEN_STAGING = 'rootleAccessToken'; // dev2staging
export const COOKIE_AUTH_REFRESH_TOKEN = '_rootle_art';
export const COOKIE_NAME_DATA = '_rootle_data';