import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoPayPal, LazySvgoApps, LazySvgoBag, LazySvgoCart, LazySvgoChart, LazySvgoComments, LazySvgoDocument, LazySvgoEbay, LazySvgoEmpty, LazySvgoHendtLogo, LazySvgoNote, LazySvgoPfeilRechts3, LazySvgoPortrait, LazySvgoProduct, LazySvgoReplied, LazySvgoRootleAppLogo, LazySvgoSend, LazySvgoSent, LazySvgoSettings, LazySvgoSupport, LazySvgoTranslate } from '#components'
const lazyGlobalComponents = [
  ["SvgoPayPal", LazySvgoPayPal],
["SvgoApps", LazySvgoApps],
["SvgoBag", LazySvgoBag],
["SvgoCart", LazySvgoCart],
["SvgoChart", LazySvgoChart],
["SvgoComments", LazySvgoComments],
["SvgoDocument", LazySvgoDocument],
["SvgoEbay", LazySvgoEbay],
["SvgoEmpty", LazySvgoEmpty],
["SvgoHendtLogo", LazySvgoHendtLogo],
["SvgoNote", LazySvgoNote],
["SvgoPfeilRechts3", LazySvgoPfeilRechts3],
["SvgoPortrait", LazySvgoPortrait],
["SvgoProduct", LazySvgoProduct],
["SvgoReplied", LazySvgoReplied],
["SvgoRootleAppLogo", LazySvgoRootleAppLogo],
["SvgoSend", LazySvgoSend],
["SvgoSent", LazySvgoSent],
["SvgoSettings", LazySvgoSettings],
["SvgoSupport", LazySvgoSupport],
["SvgoTranslate", LazySvgoTranslate],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
