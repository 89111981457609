import {WAC_PATH} from '@shared';
import {BaseRepository} from './base.repository';

export default class InvoiceRepository extends BaseRepository {
  async getInvoice(invoiceId: number) {
    return await this.get(`${WAC_PATH}/reports/invoices/${invoiceId}`);
  }

  async invoicePreview(layout: any) {
    return await this.post(`${WAC_PATH}/reports/preview/invoice`, {
      body: layout
    });
  }
}
