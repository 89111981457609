import {WORKSPACE_PATH} from '@shared';
import {BaseRepository} from './base.repository';

export default class WorkspacesRepository extends BaseRepository {
  async addWorkspace(name: string, description: string) {
    return await this.post(`/workspaces`, {
      body: {
        name,
        description
      }
    });
  }

  async deleteWorkspace(workspaceId: number) {
    return await this.delete(`/workspaces/${workspaceId}`);
  }

  async disconnectWorkspace(workspaceId: number) {
    return await this.post(`/workspaces/${workspaceId}/disconnect`);
  }

  async updateWorkspaceSettings(workspaceId: number, settings: any) {
    return await this.patch(`/workspaces/${workspaceId}/settings`, {
      body: {settings}
    });
  }

  async updateWorkspaceName(workspaceId: number, name: string) {
    return await this.patch(`/workspaces/${workspaceId}/name`, {
      body: {
        name
      }
    });
  }

  async updateWorkspaceDescription(workspaceId: number, description: string) {
    return await this.patch(`/workspaces/${workspaceId}/description`, {
      body: {description}
    });
  }

  async getWorkspaceInvitations() {
    return await this.get(`/workspaces/invitations`);
  }

  async acceptInvitation(token: string) {
    return await this.post(`/workspaces/invitations`, {
      body: {
        token
      }
    });
  }

  // Members
  async addMember({email, channels, admin}: any): Promise<any> {
    const data = await this.post(`${WORKSPACE_PATH}/members`, {
      body: {
        email,
        channels,
        admin
      }
    }) as any;

    return data.member;
  }

  async resendInvitation(userId: number): Promise<any> {
    return await this.post(`${WORKSPACE_PATH}/members/resend-invitation`, {
      body: {
        userId
      }
    });
  }

  async updateWorkspaceMember({userId, channels, admin}: any): Promise<any> {
    return await this.post(`${WORKSPACE_PATH}/members/${userId}`, {
      body: {
        channels,
        admin
      }
    });
  }

  async deleteMember({userId}: any): Promise<any> {
    return await this.delete(`${WORKSPACE_PATH}/members/${userId}`);
  }

  async loadWorkspaceMembers(): Promise<any> {
    const data = await this.get(`${WORKSPACE_PATH}/members`) as any;

    return data.members;
  }

  async loadWorkspaceMember({userId}: any): Promise<any> {
    const data = await this.get(`${WORKSPACE_PATH}/members/${userId}`) as any;

    return data.member;
  }
}
