export async function useAPI<T>(
  url: string,
  opts?: Parameters<typeof $fetch<T>>[1] & {fatal?: boolean},
) {
  const {$log, $api} = useNuxtApp();
  const {fatal, ...options} = opts || {};

  try {
    return await $api(url, options);
  } catch (error: any) {
    $log.debug(error)

    if (process.server && fatal) {
      throw createError({
        statusCode: error.statusCode || 500,
        statusMessage: error.statusMessage,
        cause: error,
        fatal: true
      });
    } else if (fatal) {
      showError({
        statusCode: error.statusCode || 500,
        statusMessage: error.statusMessage,
        cause: error
      })
    }

    throw error;
  }
}
