export const useOrdersStore = defineStore('ordersStore', {

  state: () => ({
    order: null as null | any,
    loadingOrderId: null as null | string,
    loading: false
  }),

  getters: {
    fetchState: (state) => state.loadingOrderId || state.order
  },

  actions: {
    reset() {
      this.$reset();
    },

    setOrder({order}: any) {
      this.order = order;
      this.loading = false;
    },

    setLoading({loading, orderId}: { loading: boolean, orderId: string | null }) {
      this.loading = loading;
      this.loadingOrderId = orderId;

      this.order = null;
    },

    async fetchOrder({orderId, refresh}: { orderId: string, refresh: boolean }) {
      if (this.loadingOrderId === orderId && !refresh) {
        return;
      }

      this.setLoading({loading: true, orderId});

      try {
        const result = await $repositories.eBay.getOrder(orderId, refresh);
        this.setOrder(result);
      } catch (error) {
        this.setLoading({loading: false, orderId: null});
        const {capture} = useLog()
        capture(error);
      }
    }
  },
});
