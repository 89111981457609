import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';

export default defineNuxtPlugin(() => {
  ChartJS.register(Title, Tooltip, Legend, BarElement,
    ArcElement, CategoryScale, LinearScale, BarController, DoughnutController);
});