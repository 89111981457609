import {WORKSPACE_PATH} from '@shared';
import {BaseRepository} from './base.repository';

const TEMPLATES = 'message-templates';
const CATEGORIES = 'message-template-categories';

export default class MessageTemplatesRepository extends BaseRepository {

  // Message Templates
  async getTemplates() {
    return await this.get(`${WORKSPACE_PATH}/${TEMPLATES}`);
  }

  async getTemplate(id: number) {
    return await this.get(`${WORKSPACE_PATH}/${TEMPLATES}/${id}`);
  }

  async deleteTemplate(id: number) {
    return await this.delete(`${WORKSPACE_PATH}/${TEMPLATES}/${id}`);
  }

  async addTemplate(template: string, name: string, category: number | null = null) {
    return await this.post(`${WORKSPACE_PATH}/${TEMPLATES}`, {
      body: {
        name,
        template,
        category
      }
    });
  }

  async updateTemplate(id: number, template: string, name: string, category: number | null = null) {
    return await this.patch(`${WORKSPACE_PATH}/${TEMPLATES}/${id}`, {
      body: {
        name,
        template,
        category
      }
    });
  }

  // Categories
  async addCategory(name: string, settings: any = {}) {
    return await this.post(`${WORKSPACE_PATH}/${CATEGORIES}`, {
      body: {
        name,
        settings
      }
    });
  }

  async getCategories() {
    return await this.get(`${WORKSPACE_PATH}/${CATEGORIES}`);
  }

  async getCategory(id: number) {
    return await this.get(`${WORKSPACE_PATH}/${CATEGORIES}/${id}`);
  }

  async deleteCategory(id: number) {
    return await this.delete(`${WORKSPACE_PATH}/${CATEGORIES}/${id}`);
  }

  async updateCategory(id: number, name: string, settings: any = {}) {
    return await this.patch(`${WORKSPACE_PATH}/${CATEGORIES}/${id}`, {
      body: {
        name,
        settings
      }
    });
  }

  async changeCategoryPosition(id: number, position: number) {
    return await this.put(`${WORKSPACE_PATH}/${CATEGORIES}/${id}/position/${position}`);
  }
}
