export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  if (config.public.stage !== 'production') {
    return
  }

  const route = useRoute();
  watch(() => route.path, (to, from) => {
    console.log(to, from)
    const matomo = window.Matomo.getAsyncTracker();
    if (matomo) {
      matomo.setReferrerUrl(window.location.origin + from);
      matomo.setCustomUrl(window.location.origin + to);
      matomo.trackPageView()
    }
  })

  var _paq = window._paq = window._paq || [];
  _paq.push(["disableCookies"]);
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    var u="//matomo.hendt.de/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '2']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();
})