export default defineNuxtPlugin(() => {
  return {
    provide: {
      role: (workspace: any, role: string) => {
        if (!workspace) {
          return false;
        }

        if (workspace.is_owner) {
          return true;
        }

        return workspace.roles.includes(role);
      }
    }
  };
});