import {INQUIRY_OPTIONS} from '@shared';

export const useInquiryStore = defineStore('inquiryStore', {
  state: () => ({
    loading: false,
    inquiry: null as null | any
  }),

  getters: {
    canReply(state): boolean {
      if (!state.inquiry) {
        return false;
      }

      return !!state.inquiry.actions.find((action: any) => action.id === INQUIRY_OPTIONS.sendMessage);
    },
  },

  actions: {

    reset() {
      this.$reset();
    },

    setLoading(loading: boolean) {
      this.inquiry = null;
      this.loading = loading;
    },

    setInquiry(inquiry: any) {
      this.inquiry = inquiry;
      this.loading = false;
    },

    async fetchInquiry(inquiryId: string) {
      this.setLoading(true);
      try {
        const response = await $repositories.eBay.getInquiry(inquiryId) as any;
        this.setInquiry(response.inquiry);

        const ordersStore = useOrdersStore();
        // Check if already have a order that will be loaded
        if (response.inquiry.orderId && !ordersStore.fetchState) {
          await ordersStore.fetchOrder({orderId: response.inquiry.orderId, refresh: false});
        }
      } catch (error) {
        const {capture} = useLog();
        capture(error);
        this.setLoading(false);
      }
    },

    proceed({inquiryId, option}: { inquiryId: string, option: string }): Promise<any> {
      return $repositories.eBay.proceedInquiry(inquiryId, option);
    },

    provideShipmentInfo({inquiryId, shipment}: { inquiryId: string, shipment: any }): Promise<any> {
      return $repositories.eBay.inquiryProvideShipmentInfo(inquiryId, shipment);
    },

    async reply({text, inquiryId}: any): Promise<any> {
      const response = await $repositories.eBay.inquiryReply(inquiryId, text) as any;

      useThreadStore().addMessage(response.message);

      return response;
    }
  }
});
