import {WORKSPACE_PATH} from '@shared';
import {BaseRepository} from './base.repository';

export default class AutomationRepository extends BaseRepository {
  async getAutomations() {
    return await this.get(`${WORKSPACE_PATH}/automations`);
  }

  async removeAutomation(automationId: number) {
    await this.delete(`${WORKSPACE_PATH}/automations/${automationId}`);
  }

  async addAutomation(name: string, trigger: string, conditions: any[], channels: number[]) {
    return await this.post(`${WORKSPACE_PATH}/automations`, {
      body: {
        name,
        trigger
      }
    });
  }

  async update(automationId: number, name: string, trigger: string, conditions: any[], channels: number[]) {
    return await this.patch(`${WORKSPACE_PATH}/automations/${automationId}`, {
      body: [{
        key: 'name',
        value: name
      }, {
        key: 'trigger',
        value: trigger
      }, {
        key: 'conditions',
        value: conditions
      }, {
        key: 'channels',
        value: channels
      }
      ]
    });
  }

  async toggleActiveState(automationId: number, active: boolean) {
    return await this.patch(`${WORKSPACE_PATH}/automations/${automationId}`, {
      body: [{
        key: 'active',
        value: active
      }]
    });
  }

  // -- Tasks
  async addTask(automationId: number, task: any) {
    return await this.post(`${WORKSPACE_PATH}/automations/${automationId}/tasks`, {body: task});
  }

  async removeTask(automationId: number, taskId: number) {
    return await this.delete(`${WORKSPACE_PATH}/automations/${automationId}/tasks/${taskId}`);
  }

  async addAction(automationId: number, taskId: number, name: string, type: string, appId: number | null) {
    return await this.post(`${WORKSPACE_PATH}/automations/${automationId}/tasks/${taskId}/actions`, {
      body: {
        name,
        type,
        appId
      }
    });
  }

  async removeAction(automationId: number, taskId: number, actionId: number) {
    return await this.delete(`${WORKSPACE_PATH}/automations/${automationId}/tasks/${taskId}/actions/${actionId}`);
  }

  // Events
  async fetchEvents(automationId: number) {
    return await this.get(`${WORKSPACE_PATH}/automations/${automationId}/events`);
  }
}

