import {EBAY_PATH, INQUIRY_OPTIONS} from '@shared';
import {BaseRepository} from './base.repository';

export default class EBayRepository extends BaseRepository {

  async getItem(itemId: string) {
    return await this.get(`${EBAY_PATH}/items/${itemId}`);
  }

  // eBay details
  async getDetails(name: string) {
    return await this.get(`${EBAY_PATH}/details/${name}`);
  }

  // Fulfillment
  async provideShipmentInfo(orderId: string, shipment: any) {
    return await this.post(`${EBAY_PATH}/orders/${orderId}/fulfillment/shipments`, {
      body: shipment
    });
  }

  async issueRefund(orderId: string, refund: any) {
    return await this.post(`${EBAY_PATH}/orders/${orderId}/fulfillment/refunds`, {
      body: refund
    });
  }

  // Cancellation
  async createCancellation(legacyOrderId: string, cancel: any) {
    return await this.post(`${EBAY_PATH}/cancellation/${legacyOrderId}`, {
      body: cancel
    });
  }

  async getCancellation(cancelId: string) {
    return await this.get(`${EBAY_PATH}/cancellation/${cancelId}`);
  }

  async checkCancellationEligibility(legacyOrderId: string) {
    return await this.get(`${EBAY_PATH}/cancellation/${legacyOrderId}/check-eligibility`);
  }

  async proceedCancellation(cancelId: string, option: string) {
    return await this.post(`${EBAY_PATH}/cancellation/${cancelId}/${option}`);
  }

  // Orders
  async getOrder(orderId: string, refresh = false) {
    return await this.get(`${EBAY_PATH}/orders/${orderId}`, refresh ? {
      params: {
        refresh
      }
    } : {});
  }

  async getSales(pageNo: number = 1, query: string | null = null, from: string | null = null, to: string | null = null) {
    return await this.get(`${EBAY_PATH}/orders/sales`, {
      params: {
        pageNo,
        query,
        from,
        to
      }
    });
  }

  // Chat & Messages
  async searchMessages(query: string) {
    return await this.get(`${EBAY_PATH}/messages/search`, {
      params: {
        query
      }
    });
  }

  // inquiry
  public async getInquiry(inquiryId: string) {
    return await this.get(`${EBAY_PATH}/inquiry/${inquiryId}`);
  }

  public async proceedInquiry(inquiryId: string, option: string) {
    return await this.post(`${EBAY_PATH}/inquiry/${inquiryId}/${option}`);
  }

  public async inquiryProvideShipmentInfo(inquiryId: string, shipment: any){
    return await this.post(`${EBAY_PATH}/inquiry/${inquiryId}/${INQUIRY_OPTIONS.provideShipmentInfo}`, {
      body: shipment
    });
  }

  public async inquiryReply(inquiryId: string, text: string) {
    return await this.post(`${EBAY_PATH}/inquiry/${inquiryId}/reply`, {
      body: {
        text,
        inquiryId,
      }
    });
  }

  // Notifications
  public async getNotifications() {
    return await this.get(`${EBAY_PATH}/notifications`);
  }
  public async subscribeNotifications() {
    return await this.post(`${EBAY_PATH}/notifications/subscribe`);
  }
  public async unsubscribeNotifications() {
    return await this.post(`${EBAY_PATH}/notifications/unsubscribe`);
  }

  // Rate Limit/Usage
  public async getRateLimits() {
    return await this.get(`/admin/rateLimits`);
  }

}