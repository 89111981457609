import {WAC_PATH} from '@shared';
import {BaseRepository} from './base.repository';

export default class AnalyticsRepository extends BaseRepository {
  async getRevenue(timeRange: number, groupBy: string) {
    return await this.get(`${WAC_PATH}/analytics/revenue`, {
      params: {
        timeRange,
        groupBy
      }
    });
  }

  async getRevenueComparison() {
    return await this.get(`${WAC_PATH}/analytics/revenue/comparison`);
  }

  async getNotFulfilledOrders() {
    return await this.get(`${WAC_PATH}/analytics/not-fulfilled-orders`);
  }

  async getOrdersReport() {
    return await this.get(`${WAC_PATH}/analytics/orders-report`);
  }

  async getConversionRate() {
    return await this.get(`${WAC_PATH}/analytics/conversion-rate`);
  }

  async getImpressions() {
    return await this.get(`${WAC_PATH}/analytics/impressions`);
  }

  async getMostSoldItems() {
    return await this.get(`${WAC_PATH}/analytics/most-sold-items`);
  }

  async getFeedback() {
    return await this.get(`${WAC_PATH}/analytics/feedback`);
  }

  async getNewMessageCount() {
    return await this.get(`${WAC_PATH}/analytics/new-message-count`);
  }

  async getSellingSummary(days: number) {
    return await this.get(`${WAC_PATH}/analytics/selling-summary`, {
      params: {
        days
      }
    });
  }
}
