import {BaseRepository} from './base.repository';

export default class TranslationRepository extends BaseRepository {
  async translate(text: string, targetLanguage: string) {
    const data = await this.post('/translate', {
      body: {
        text,
        targetLanguage,
      }
    }) as any;
    return data.text;
  }
}
