import {BaseRepository} from './base.repository';

export default class NotesRepository extends BaseRepository {
  async deleteNote(id: number) {
    return await this.delete(`/notes/${id}`);
  }

  async addMessageNote(threadId: string, note: string, robot = false) {
    return await this.post(`/notes/:workspace/:channel/message`, {
      body: {
        key: threadId,
        note,
        robot
      }
    });
  }
}
