import revive_payload_client_4sVQNw7RlN from "/home/runner/work/rootle/rootle/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/rootle/rootle/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/rootle/rootle/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/rootle/rootle/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/rootle/rootle/client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/rootle/rootle/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/rootle/rootle/client/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/rootle/rootle/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/home/runner/work/rootle/rootle/client/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/rootle/rootle/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _00_log_IWv9YLS792 from "/home/runner/work/rootle/rootle/client/src/plugins/00.log.ts";
import _02_api_TvyVDP0goZ from "/home/runner/work/rootle/rootle/client/src/plugins/02.api.ts";
import clipboard_client_7n53gJubeH from "/home/runner/work/rootle/rootle/client/src/plugins/clipboard.client.ts";
import color_picker_client_VpEsGLbntr from "/home/runner/work/rootle/rootle/client/src/plugins/color-picker.client.ts";
import draggable_client_Wa5PLSqOHq from "/home/runner/work/rootle/rootle/client/src/plugins/draggable.client.ts";
import io_client_C7NM8U2hT3 from "/home/runner/work/rootle/rootle/client/src/plugins/io.client.ts";
import matomo_client_1hOWyQFi23 from "/home/runner/work/rootle/rootle/client/src/plugins/matomo.client.ts";
import pdf_merger_client_O6t66MmqIG from "/home/runner/work/rootle/rootle/client/src/plugins/pdf-merger.client.ts";
import role_checker_AWWx1rcGrI from "/home/runner/work/rootle/rootle/client/src/plugins/role-checker.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/rootle/rootle/client/src/plugins/sentry.client.ts";
import utils_uiWDVOCzev from "/home/runner/work/rootle/rootle/client/src/plugins/utils.ts";
import vue_chart_client_bEOZJzCyTf from "/home/runner/work/rootle/rootle/client/src/plugins/vue-chart.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _00_log_IWv9YLS792,
  _02_api_TvyVDP0goZ,
  clipboard_client_7n53gJubeH,
  color_picker_client_VpEsGLbntr,
  draggable_client_Wa5PLSqOHq,
  io_client_C7NM8U2hT3,
  matomo_client_1hOWyQFi23,
  pdf_merger_client_O6t66MmqIG,
  role_checker_AWWx1rcGrI,
  sentry_client_KAXFuL2wum,
  utils_uiWDVOCzev,
  vue_chart_client_bEOZJzCyTf
]