import * as Sentry from '@sentry/vue';

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const config = useRuntimeConfig();
    if (typeof config.public.sentryDsn !== 'string' || config.public.stage !== 'production') {
      return
    }

    const router = useRouter();
    const browserTracing = Sentry.browserTracingIntegration({
      router
    })

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: config.public.sentryDsn,
      integrations: [browserTracing],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/app.rootle\.de/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
});
