import {WORKSPACE_PATH} from '@shared';
import {BaseRepository} from './base.repository';

export default class OrderDocumentsRepository extends BaseRepository{
  async getInvoiceLayouts() {
    return await this.get(`${WORKSPACE_PATH}/order-documents/layouts?type=INVOICE`);
  }

  async addLayout(form: any) {
    return await this.post(`${WORKSPACE_PATH}/order-documents/layouts`, {
      body: form
    });
  }

  async changeLayout(layoutId: number, form: any) {
    return await this.patch(`${WORKSPACE_PATH}/order-documents/layouts/${layoutId}`, {
      body: form
    });
  }

  async removeLayout(layoutId: number) {
    return await this.delete(`${WORKSPACE_PATH}/order-documents/layouts/${layoutId}`);
  }

  async getDefaultHtmlTemplates(type: string) {
    return await this.get(`${WORKSPACE_PATH}/order-documents/html-templates/${type}`);
  }

  // -- Number Groups
  async getNumberGroups() {
    return await this.get(`${WORKSPACE_PATH}/order-documents/number-groups`);
  }

  async addNumberGroup(numberGroup: any) {
    return await this.post(`${WORKSPACE_PATH}/order-documents/number-groups`, {
      body: numberGroup
    });
  }

  async changeNumberGroup(numberGroupId: number, numberGroup: any) {
    return await this.patch(`${WORKSPACE_PATH}/order-documents/number-groups/${numberGroupId}`, {
      body: numberGroup
    });
  }

  async removeNumberGroup(numberGroupId: number) {
    return await this.delete(`${WORKSPACE_PATH}/order-documents/number-groups/${numberGroupId}`);
  }
}
