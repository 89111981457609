import {useCancelStore} from '@/stores/cancel';
import {useFoldersStore} from '@/stores/folders';
import {useInquiryStore} from '@/stores/inquiry';
import {useItemsStore} from '@/stores/items';
import {EBAY_PATH, ErrorCodes, type Message, type Thread as ThreadType} from '@shared';

export const useThreadStore = defineStore('threadStore', {
  state: () => ({
    loading: false,
    thread: null as null | any,
    original: null as null | any,
  }),

  actions: {
    reset() {
      this.$reset();
    },

    loadingState() {
      this.loading = true;
      this.thread = null;
    },

    setThread(thread: any) {
      this.thread = thread;
      this.loading = false;
    },

    lock({threadId, locked}: { threadId: string, locked: boolean }) {
      if (this.thread?.id === threadId) {
        this.thread.locked = locked;
      }
    },

    setMessageState({ids, key, value}: { ids: string[], key: string, value: boolean }) {
      ids.forEach(id => {
        if (this.thread && this.thread.messages) {
          const index = this.thread.messages.findIndex((message: any) => {
            return message.id === id;
          });
          if (index !== -1) {
            this.thread.messages[index][key] = value;
          }
        }
      });
    },

    addMessage(message: any) {
      if (this.thread) {
        this.thread.messages.push(message);
      } else {
        //  $sentry.captureException(new Error('Thread is not set!'));
      }
    },

    deleteMessage(id: number) {
      this.thread.messages = this.thread.messages.filter((message: any) => message.id !== id);
    },

    addReply({threadId, message}: { threadId: string, message: Message }) {
      if (this.thread && this.thread.id === threadId) {
        this.thread.messages.push(message);
      }
    },

    setOriginal(original: any) {
      if (!original) {
        this.original = null;
        return;
      }

      const {html, subject} = original;
      this.original = {html, subject};
    },

    async fetchThread({threadId}: any) {
      if (!threadId) {
        return;
      }

      this.resetThreadData();
      this.loadingState();

      let foldersStore = useFoldersStore();
      foldersStore.selectMessage(threadId);

      try {
        const response = await $api(`${EBAY_PATH}/messages/threads/${threadId}`) as any;

        const thread = response.thread;
        this.setThread(thread);
        await this.loadData(thread);
      } catch (e: any) {
        if (e.data?.code === ErrorCodes.THREAD_EBAY_USER_DOES_NOT_EXISTS) {
          this.setThread({
            id: 'error',
            message: 'eBay-Nutzer konnte nicht gefunden werden.'
          });
        } else {
          // $sentry.captureException(e);
        }
      }
    },

    closeThread() {
      this.resetThreadData();
      this.setThread(null);
      const foldersStore = useFoldersStore();
      foldersStore.selectMessage(null);
    },

    resetThreadData() {
      useReturnStore().reset();
      useItemsStore().reset();
      useInquiryStore().reset();
      useCancelStore().reset();
      useOrdersStore().reset();
    },

    async loadData(thread: ThreadType) {
      const promises = []
      if (thread.refs.orderId) {
        promises.push(useOrdersStore().fetchOrder({orderId: thread.refs.orderId, refresh: false}))
      } else if (thread.refs.itemId) {
        promises.push(useItemsStore().fetchItem(thread.refs.itemId))
      }

      if (thread.refs.inquiryId) {
        promises.push(useInquiryStore().fetchInquiry(thread.refs.inquiryId))
      }

      if (thread.refs.cancelId) {
        promises.push(useCancelStore().fetchCancel(thread.refs.cancelId))
      }

      if (thread.refs.returnId) {
        promises.push(useReturnStore().fetchReturn(thread.refs.returnId))
      }

      await Promise.allSettled(promises)
    },

    showOriginalMessage({html, subject}: any) {
      this.setOriginal({html, subject});
    },

    closeOriginalMessage() {
      this.setOriginal(null);
    }
  }
});
